body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input[type='date']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

.Layout-content-4 {
  width: calc(100vw - 255px);
}

/* customize some form styles */
.MuiFormControl-root.separated-control {
  padding: 15px;
  border-top: 1px rgba(0, 0, 0, 0.3) solid;
  border-bottom: 1px rgba(0, 0, 0, 0.3) solid;
  margin: 15px -15px;
  width: calc(100% + 30px);
  background: rgba(0, 0, 0, 0.02);
}

.MuiFormControl-root.separated-control .MuiInputLabel-formControl {
  top: 15px;
  left: 15px;
}

/* hide fieldArray index */
.input-list > li > div {
  display: none;
}

.input-list > li > section {
  display: flex;
  align-items: center;
  gap: 16px;
}

.input-list > li > section > .ra-input {
  flex: 1;
}

.input-list > li > section > .ra-input + .ra-input {
  flex: 3;
}

.input-list > li > section > .ra-input + .ra-input p {
  display: none;
}
