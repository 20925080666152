.MuiAutocomplete-popup,
.RaAutocompleteSuggestionList-suggestionsContainer-371,
div[role='tooltip'] {
  z-index: 999999 !important;
}

.clone-modal {
  display: flex;
  justify-content: space-between;
}

.clone-modal .picker {
  flex: 1;
}

.clone-modal .MuiFormControl-root {
  width: 96%;
}
